import { render, staticRenderFns } from "./Logos.vue?vue&type=template&id=987cb2de&scoped=true&"
import script from "./Logos.vue?vue&type=script&lang=js&"
export * from "./Logos.vue?vue&type=script&lang=js&"
import style0 from "./Logos.vue?vue&type=style&index=0&id=987cb2de&prod&scoped=true&lang=scss&"
import style1 from "./Logos.vue?vue&type=style&index=1&id=987cb2de&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "987cb2de",
  null
  
)

export default component.exports